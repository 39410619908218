<template>
    <Introduction :url="''" :class="'introduction-cours-florent'">  
        <template #title>
            Cours Florent
        </template>
        <template #speciality>
             театральная школа
        </template>
        <template #min-duration>
            От 3 года
        </template>
        <template #cost>
            7.000 евро/год 
        </template>
        <template #campus>
            Париж
        </template>
    </Introduction>  
</template>
<script>
import Introduction from '@/components/schools/slots/Introduction.vue'
export default {
    components:{
        Introduction
    }
}
</script>