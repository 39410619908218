<template>
    <SectionsNav>
        <template #introduction>
            <a class="nav-link" @click="showSection('presentation')">Обзор</a>
        </template>
        <template #programs>
            <a class="nav-link" @click="showSection('programs')">Программы</a>
        </template>
        <template #campuses>
            <a class="nav-link" @click="showSection('campus')">Кампусы</a>
        </template>
        <template #selectSection>
            <select id="select-mobile-menu" class="form-select form-select-lg" v-model="this.currentSection">
                <option value="presentation">Обзор</option>
                <option value="programs">Программы</option>
                <option value="campus">Кампусы</option>
            </select>
        </template>
    </SectionsNav>
    
    <Obzor v-if="currentSection === 'presentation'" ref="presentation" class="presentation">
        <template #title>
            Обзор
        </template>
        <template #description> 
            Cours Florent - это всемирно известная многопрофильная театральная школа в Париже с 50-летним опытом.
            <br>
            Когда Франсуа Флоран основал Cours Florent в 1967 году, его видение состояло в том, чтобы создать уникальные и инновационные программы, ориентированные на каждого отдельного студента.
            Театральная школа предлагает художественные и междисциплинарные программы, ориентированные не только на актерское мастерство, но и на постановку, музыку и танцы.
            <br>
            Список выпускников впечатляет. От Изабель Аджани до Гада Эльмале, от Даниэля Отёя, Дени Подалидеса, Изабель Карре, Гийома Кане, Одри Тоту, Эдуарда Баера до Марины Хендс… Все они тренировались в Cours Florent.
            <br>
            Вы хотите изучать театр, актерское мастерство или и то, и другое? Учеба в Cours Florent предоставит вам ценный опыт и знания, которые можно применить в будущей карьере в области театра, кино или рекламы. Кастинг-офис школы доступен для студентов, поскольку директора по кастингу, художественные агенты и продюсерские компании регулярно размещают в школе свои предложения.
            </template>
        <template #orientation>
            Bachelor
        </template>
        <template #youtube>
            <iframe width="450" height="315" src="https://www.youtube.com/watch?v=4JD0xtOGBBA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </template>
        <template #speciality>
            театральная школа
        </template>
    </Obzor>


    <section v-if="currentSection === 'programs'" ref="programs" class="programs">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 title-main">
                            <h1>Программы</h1>
                        </div>
                    </div>
                    
                    <div class="row program-select-wrapper">
                        <div class="col-12 col-lg-4">
                            <select v-model="program" ref="programSelector" class="form-select form-select-lg">
                                <option disabled value="">Выбрать программу</option>
                                <option value="aie">Acting in English</option>
                                </select>
                        </div>
                    </div>
                    
                    <Program :url="''" v-if="this.program === 'aie'" id="aie">
                        <template #title>
                            Acting in English 
                        </template>
                        <template #description>
                            <p>
                                Эти курсы драмы длятся три года, основаны на американских и английских театральных методах и каждый год включают разные экзамены. Заведующий кафедрой встречается с каждым студентом для постановки личных задач. Студентам также предоставляется возможность изучать актерское мастерство для кино и телевидения.
                            </p>
                        </template>
                        <template #duree>
                            3 года
                        </template>
                        <template #language>
                            английский 
                        </template>
                        <template #cost>
                             7.000 евро/год 
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #requirements>
                            английский уровня B2, среднее образование, досье.
                        </template>
                        <template #start-time>
                            сентябрь 
                        </template>
                    </Program>

                </div>
            </div>
        </div>
    </section>

    <CampusMain v-if="currentSection === 'campus'" ref="campus" class="campus">
        <template #campuses>
            <Campus>
                <template #localisation>
                    Париж
                </template>
                <template #description>
                    Школа расположена в 19-м округе Парижа. Исторический кампус театральной школы сегодня остается учебной лабораторией в самом центре столицы.
                </template>
                <template #iframe-youtube>
                    <iframe width="450" height="315" src="https://www.youtube.com/watch?v=ARqUhiwtTAk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </template>
            </Campus>
        </template>
    </CampusMain>

</template>
<script>
import Program from '@/components/schools/slots/Program.vue'
import CampusMain from '@/components/schools/slots/CampusMain.vue'
import SectionsNav from '@/components/schools/slots/SectionsNav.vue'
import Obzor from '@/components/schools/slots/Obzor.vue'
import Campus from '@/components/schools/slots/Campus.vue'

export default {
    data(){
        return {
            currentSection:"presentation",
            program : ""
        }
    },
    components:{
        Program,
        CampusMain,
        SectionsNav,
        Obzor,
        Campus
    },
    methods:{
        showSection(section){
            this.currentSection = section;
            this.desktopScroll();
        },
        desktopScroll(){
            let scrollToElement = document.querySelector(".menu")
            scrollToElement.scrollIntoView({behavior: "smooth", block: "start"});
        }
    }
}
</script>